import {cva, type VariantProps} from 'class-variance-authority';
import type {PropsWithChildren} from 'react';
import classNames from 'classnames';

import Image, {type ImageProps} from '@/components/base/elements/Image/Image';
import {twMerge} from '@/stylesheets/twMerge';

const styles = cva(
  'container relative flex flex-col sm:flex-row rounded-2xl overflow-hidden',
  {
    variants: {
      mode: {
        dark: 'bg-fullwidthcard-dark-bg text-fullwidthcard-dark-text',
        light: 'bg-fullwidthcard-light-bg text-fullwidthcard-light-text',
      },
      visualPosition: {
        left: 'sm:flex-row',
        right: 'sm:flex-row-reverse',
      },
    },
    defaultVariants: {
      visualPosition: 'left',
    },
  },
);

export interface FullWidthCardProps extends VariantProps<typeof styles> {
  className?: string;
  image?: ImageProps;
  mode?: 'light' | 'dark';
  visualPosition?: 'left' | 'right';
}

/**
 * Separate `className` from the rest of the image props to pass to `twMerge`.
 * This allows custom styling to be passed to the image without clobbering the
 * default `<Image />` styles.
 */
function splitImageProps(image?: ImageProps) {
  if (image) {
    const {className, ...imageRest} = image;
    return {
      imageClassName: className,
      imageRest,
    };
  }

  return {
    imageClassName: undefined,
    imageRest: undefined,
  };
}

export default function FullWidthCard({
  children,
  className,
  image,
  mode = 'dark',
  visualPosition = 'left',
}: PropsWithChildren<FullWidthCardProps>) {
  const {imageClassName, imageRest} = splitImageProps(image);
  const componentName = 'full-width-card';

  return (
    <div
      className={twMerge(styles({mode, visualPosition}), className)}
      data-mode={mode}
      data-component-name={componentName}
    >
      <div
        className={classNames('flex basis-1/2', {
          'place-content-end': visualPosition === 'right',
        })}
      >
        {imageRest?.src && (
          <Image
            className={twMerge(
              'w-full h-full grow object-cover',
              imageClassName ?? '',
            )}
            {...imageRest}
          />
        )}
      </div>
      <div className="grid basis-1/2 sm:place-content-center gap-y-md p-8 md:p-10 lg:p-16">
        {children}
      </div>
    </div>
  );
}
